.__container-input {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.__parcel-input {
  flex: 1;
}

.__label-agendamento {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  margin-bottom: 8px;
}

.__text-error {
  font-size: 12px;
  margin-top: 3px;
}

.__table-agendamento {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  overflow: auto;
}

.__tr-agendamento-head {
  border-bottom: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 7px 15px
}

.__tr-agendamento {
  border-bottom: 1px solid #E5E5E5;
  border-radius: 10px
}

.__tr-agendamento:last-child {
  border-bottom: none;
  border-radius: 10px
}

.__table-agendamento table tr td {
  padding: 7px 15px;
}

.__td-status {
  background-color: #B7FFD4;
  border-radius: 10px;
  text-align: center;
  max-width: 86px;
}

@media only screen and (max-width: 768px) {
  .__container-input {
    margin-bottom: 30px;
  }
}
